import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"
import ContactCategoriesQuery from "../queries/contactCategories"

const ImageGrid = ({ images, alt }) => (
  <div className="image-grid">
    {images.map((image, index) => (
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        key={index}
        alt={`${alt} ${index}`}
      />
    ))}
  </div>
)

const GetInContact = () => {
  const contactCategories = ContactCategoriesQuery()

  const contacts = contactCategories["Uthyrning"]

  return (
    <>
      <h2>Kontakt</h2>
      {contacts.map(contact => (
        <Contact
          name={contact.name}
          description={contact.description}
          phone={contact.phone}
          mobile={contact.mobile}
          key={contact.name}
          contactId={contact.contactId}
        />
      ))}
    </>
  )
}

const IndexPage = ({ data }) => {
  const getNodesFromQuery = edges => edges.map(edge => edge.node)

  const chapelImages = getNodesFromQuery(data.chapel.edges)
  const diningRoomImages = getNodesFromQuery(data.diningRoomImages.edges)
  const kitchenImages = getNodesFromQuery(data.kitchen.edges)
  const downstairsImages = getNodesFromQuery(data.downstairs.edges)
  const outdoorsImages = getNodesFromQuery(data.outdoors.edges)
  const upstairsImages = getNodesFromQuery(data.upstairs.edges)

  return (
    <Layout>
      <Seo title="Hyra lokaler" />
      <section className="article__content">
        <h1 className="heading">Hyra lokaler</h1>
        <p>
          Det går bra att hyra delar av eller hela kyrkan till konferens,
          föreningsmöten, fester eller annat. Här ryms upp till 150 personer.
          Lokalerna är i fint skick och praktiska för många olika typer av
          samlingar.
        </p>
        <GetInContact />
        <ImageGrid images={outdoorsImages} alt="Björkbackskyrkan" />
      </section>
      <section className="article__content">
        <h2 className="heading">Serveringslokal</h2>
        <p>
          Lokalen rymmer cirka 70 sittande personer vid bord. Här finns en
          mindre scen, ljudanläggning och digital projektor. I direkt anslutning
          finns en stor altan i vinkel mot väst och syd, möblerad med bord och
          stolar under säsong. En fin plats att sitta vid när vädret tillåter.
        </p>
        <ImageGrid images={diningRoomImages} alt="Serveringssal" />
      </section>
      <section className="article__content">
        <h2 className="heading">Kyrksalen</h2>
        <p>
          Här finns plats drygt 100 sittande personer. En större estrad finns
          längst fram i salen. Här kan dukas om till en större festdukning, här
          är högt till tak och det finns ljudanläggning och digital projektor.
          Rummet är i direkt anslutning till serveringslokalen och en vikvägg
          kan öppnas upp om ni är riktigt många.
        </p>
        <ImageGrid images={chapelImages} alt="Kyrksal" />
      </section>
      <section className="article__content">
        <h2 className="heading">Köket</h2>
        <p>
          Här lagar du avancerad mat eller fixar enkelt fika och allt
          däremellan. Här finns spis, ugnar, micro, alla köksredskap du behöver
          samt kyl och frysmöjlighet. Porslin ingår i hyran och det finns
          möjlighet att duka fint till både kaffet, lunchen och middagen. Vita
          dukar kan hyras efter överenskommelse. Separat och välutrustat diskrum
          finns i direkt anslutning till köket.
        </p>
        <ImageGrid images={kitchenImages} alt="Kök" />
      </section>
      <section className="article__content">
        <h2 className="heading">Övervåningen</h2>
        <p>
          Här finns rejäla soffor och ett biljardbord samt lite spel. Rummet
          fungerar även fint som samtalsrum när man vill sitta lite bekvämare.
        </p>
        <ImageGrid images={upstairsImages} alt="Övervåning" />
      </section>
      <section className="article__content">
        <h2 className="heading">Nedervåningen</h2>
        <p>
          Här finns möjlighet till aktiviter av olika slag i flera rum. Pingis,
          enklare innebandy för barn och ett lekrum med leksaker för de allra
          minsta. Här finns även ett par smårum att använda.
        </p>
        <ImageGrid images={downstairsImages} alt="Nedervåning" />
      </section>
      <section className="article__content">
        <h2 className="heading">Studio</h2>
        <p>
          Vi har även en musikstudio med replokal för den som är intresserad av
          det.{" "}
          <Link to="/studion" className="link">
            Läs mer på studio-sidan
          </Link>
          .
        </p>
      </section>
      <section className="article__content">
        <h2 className="heading">Ta dig hit</h2>
        <p>
          Till Björkbackskyrkan tar du dig mycket enkelt med tåg eller buss då
          stationen ligger 5 minuter från vår dörr. Restid med tåg från Uppsala
          station till vår dörr ca 30 minuter, från Gävle ca 45 minuter. Det
          finns gott om parkeringsplatser för bilar.
        </p>
      </section>
      <section className="article__content">
        <h2 className="heading">Förtäring, städning och restriktioner</h2>
        <p>
          Önskar man något enklare fika eller annat att äta så kan det ordnas
          efter överenskommelse med de möjligheter vi har för tillfället. Annars
          fixar ni det själva, mataffär och pizzeria finns inom 10 minuters
          gångavstånd. Björkbackskyrkan är en alkohol-, drog- och rökfritt
          plats.
        </p>
        <p>
          Vi förbehåller oss rätt att neka uthyrning till sällskap med åsikter
          som starkt strider mot församlingens värderingar eller kristen tro.
        </p>
        <p>
          Vår kyrka hyr man och lämnar i lika fint skick som när man kommer om
          inte annat avtalas. Städredskap finns på plats.
        </p>
        <p>
          Ljudtekniker, sång och musik? Fråga oss, ibland har vi möjlighet att
          hjälpa till även med det!
        </p>
        <p>Det är inte tillåtet att ta in några djur i våra lokaler.</p>
        <p>
          Kom ihåg att alltid informera besökare om hur de tar sig ut vid
          eventuell brand. Utrymning av lokalerna enligt uppsatta
          utrymningsplaner. Återsamlingsplats vid anslagstavlan nere vid vägen
          på nedre grusplan.
        </p>
        <p>
          Lokalerna släcks och låses när man lämnar dessa. Nyckelkort återlämnas
          enligt överenskommelse.
        </p>
      </section>
      <section className="article__content">
        <h2>Priser</h2>
        <p>Priser som anges är dagspriser.</p>
        <h3>Entreplan inklusive kök, exklusive kyrksal</h3>
        <p>
          Frånpris förening/privatperson 1 500kr
          <br />
          Frånpris företag/kommun/övrigt 3 000kr
        </p>
        <h3>Hela kyrkan, exklusive studion</h3>
        <p>
          Frånpris förening/privatperson 2 500kr
          <br />
          Frånpris företag/kommun/övrigt 5 000kr
        </p>
        <h3>Begravning icke medlem</h3>
        <p>
          Frånpris 6 000kr
          <br />I priset ingår lokal, ljudtekniker samt pastor som förbereder
          samt genomför förrättningen. För minnestund i kyrkan tillkommer hyra
          för entreplan med tillgång till serveringslokal, kök och diskrum.
        </p>
        <GetInContact />
      </section>
    </Layout>
  )
}

export const query = graphql`
  fragment rentalImages on File {
    childImageSharp {
      gatsbyImageData(height: 850, quality: 80)
    }
  }

  {
    chapel: allFile(filter: { relativeDirectory: { eq: "rental/chapel" } }) {
      edges {
        node {
          ...rentalImages
        }
      }
    }
    diningRoomImages: allFile(
      filter: { relativeDirectory: { eq: "rental/dining-room" } }
    ) {
      edges {
        node {
          ...rentalImages
        }
      }
    }
    downstairs: allFile(
      filter: { relativeDirectory: { eq: "rental/downstairs" } }
    ) {
      edges {
        node {
          ...rentalImages
        }
      }
    }
    kitchen: allFile(filter: { relativeDirectory: { eq: "rental/kitchen" } }) {
      edges {
        node {
          ...rentalImages
        }
      }
    }
    outdoors: allFile(
      filter: { relativeDirectory: { eq: "rental/outdoors" } }
    ) {
      edges {
        node {
          ...rentalImages
        }
      }
    }
    upstairs: allFile(
      filter: { relativeDirectory: { eq: "rental/upstairs" } }
    ) {
      edges {
        node {
          ...rentalImages
        }
      }
    }
  }
`

export default IndexPage
